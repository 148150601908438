@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
@import url('https://use.typekit.net/erh8fzq.css');
//@import "../node_modules/bulma/sass/utilities/initial-variables.sass";




// Set your brand colors
$redish: #F25E7A;
$supergreen: #84F5C0;
$superyellow: #F2E85E;
$superblue:#30387a;
$reddawn: #FCA97B;
$darker: #111111;
$b0desYellow: rgb(255, 223, 0);
$b0desWhite: rgb(252, 241, 170);
$dark-lighter: $darker;
$darker-darker: #000000;
$white: hsl(0, 0%, 96%);
$black: hsl(0, 0%, 0%);




// Update Bulma's global variables
/* $family-sans-serif: "Nunito", sans-serif;
$primary: $redish;
$info: $superblue;
$success: $supergreen;
$warning: $superyellow;
$danger: $reddawn;
$light: $darker;
$dark: $redish;
$link: $supergreen;
$title: $reddawn;
$text: $white; */

//Primary Colors

$primary: $white !default;

$info   : $superblue !default;
$success: $supergreen !default;
$warning: $superyellow !default;
$danger: $reddawn!default;

$light: $darker !default;
$dark: $reddawn !default;


// General colors

$scheme-main       : $white !default;
$scheme-main-bis   : $white !default;
$scheme-main-ter   : $white !default;
$scheme-invert     : $black !default;
$scheme-invert-bis : $black !default;
$scheme-invert-ter : $black !default;

$background        : $darker !default;

$border            : $redish !default;
$border-hover      : $supergreen !default;
$border-light      : $reddawn !default;
$border-light-hover: $supergreen !default;

// Text colors

$text       : $white !default;
$text-invert: findColorInvert($text) !default;
$text-light : $white !default;
$text-strong: $white !default;

// Code colors

$code           : $reddawn !default;
$code-background: $background !default;

$pre            : $text !default;
$pre-background : $background !default;

// Link colors

$link              : $reddawn !default;
$link-invert       : findColorInvert($link) !default;
$link-light        : findLightColor($link) !default;
$link-dark         : findDarkColor($link) !default;
$link-visited      : $superblue !default;

$link-hover        : $white !default;
$link-hover-border : $redish !default;

$link-focus        : $superblue !default;
$link-focus-border : $superblue !default;

$link-active       : $superblue !default;
$link-active-border: $superblue !default;

// Typography
$family-sans-serif:'Share Tech Mono', monospace;
$family-monospace: "monospace";

$family-primary  : $family-sans-serif !default;
$family-secondary: $family-sans-serif !default;
$family-code     : $family-monospace !default;

//$size-small : $size-7 !default;
//$size-normal: $size-6 !default;
//$size-medium: $size-5 !default;
//$size-large : $size-4 !default;



//Specific overrides
//$widescreen-enabled: false;
//$fullhd-enabled: false;
$background: $darker-darker;
$radius: 1px;
// Update some of Bulma's component variables
$body-background-color: $background;
//Input Overrides
$control-border-width: 1px;
$input-border-color: $primary;
$input-shadow: none;
$input-boarder-size: 1px;
$input-background-color: $background;
$input-color: $white;
$input-hover-color: $supergreen;
$input-hover-border-color:$supergreen;
//Progress Overrides
$progress-bar-background-color: $darker-darker;

//Card Overrides
$card-background-color: $darker;


$navbar-background-color: transparent;



//dropdown stuff

$scheme-invert: $b0desYellow;
$dropdown-menu-min-width: 12rem;
$dropdown-content-background-color:black;
$dropdown-content-arrow:hsl(229, 53%,  53%);
$dropdown-content-offset:4px;
$dropdown-content-padding-bottom:0.5rem;
$dropdown-content-padding-top:0.5rem;
$dropdown-content-radius:4px;
$dropdown-content-shadow:0 0.5em 1em -0.125em rgba($scheme-invert, 0.1), 0 0px 0 1px rgba($scheme-invert, 0.02);
$dropdown-content-z:20;
$dropdown-item-color:$b0desYellow;
$dropdown-item-hover-color:hsl(0, 0%, 4%);
$dropdown-item-hover-background-color:hsl(0, 0%, 96%);
$dropdown-item-active-color:rgb(0, 0, 0);
$dropdown-item-active-background-color:hsl(229, 53%,  53%);
$dropdown-divider-background-color:hsl(0, 0%, 93%);

// $button-color: hsl(0, 0%, 21%);
$button-color: hsl(0, 0%, 21%);
$button-hover-color: hsl(0, 0%, 21%);
$button-background-color:$b0desWhite;
$button-border-color:$b0desYellow;

$dropdown-content-padding-bottom: 0em;
$dropdown-content-padding-top: 0em;
.dropdown-content {
    // width:min-content;
    text-align: center;
    cursor: pointer;

}



// Import only what you need from Bulma
@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";
@import "../../node_modules/bulma/sass/components/_all.sass";
@import "../../node_modules/bulma/sass/elements/_all.sass";
@import "../../node_modules/bulma/sass/form/_all.sass";
@import "../../node_modules/bulma/sass/grid/_all.sass";
@import "../../node_modules/bulma/sass/helpers/_all.sass";
@import "../../node_modules/bulma/sass/layout/_all.sass";


