.threeD {
    height: 100vh !important;
}
.is-transparentboi{
  
    background-color: transparent !important;
    background: transparent !important;

  a{
    background-color: transparent !important;
  }
}


:root {
  font-size: 20px;
}

html::-webkit-scrollbar{
  display: none;
}

html,
body {
  margin: 0;
  padding: 0;
  background: black;
  color: #606063;
  font-family: "larabiefont",monospace;
}
html{
  overflow-y: visible;
}

#root {
  width: 100vw;
  height: 100vh;
  // overflow: scroll;
  .scroll-natural{
      // overflow: scroll !important;
  }
}

canvas {
  position: absolute;
}

.loading {
  padding: 10px;
  transform: translate3d(-50%, -50%, 0);
}

.underline{
  text-decoration: underline;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // overflow: auto;
  pointer-events: none;
}

.product {
  border: solid #59230F 1px;
  padding: 1em;
}

.product-page {
  border: solid #59230F 1px;
  padding: 1em;
  margin: 1em;

  @media (max-width: 769px) {
    text-align: center;
  }
  .product-breadcrum{
    margin-bottom: 1em;
  }

  .product-image{
    width: 500px;
    height: auto;
  }
  .product-description{
    font-size: 1em;
    margin-bottom: 1em;
  }
  .product-price{
    font-size: 2em;
    margin-bottom: 1em;
  }
  .product-title{
    font-size: 1.5em;
    margin-bottom: 1rem;
    text-decoration: underline;
  }
  .product-options{
    margin-bottom: 1em;
    .product-option-container{
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
    .product-option{
      padding: 10px;
      color: #606063;
      background-color: black;
      border: solid 1px #606063;
     &:hover{
        border: solid 1px $reddawn;
        color: $reddawn;
      }
      &.active{
        border: solid 1px $reddawn;
        color: $reddawn;
      }
    }
  }
  .product-image-gallery{
    .product-images-container{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
    }
    .product-image-aspect{
      height: 500px;
      width: auto;
      text-align: left;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    .product-images{
      max-width: 70px;
      border: solid 1px #606063;
     &:hover{
        border: solid 1px #59230F;
      }
      &.active{
        border: solid 1px #59230F;
      }
    }
  }

};
.cart{
  max-width: 1100px;
  margin: 0 auto;
  @media (max-width: 769px) {
    text-align: center;
  }
  .cart-image{
    max-width: 200px;
    width: 100%;
    text-align: center;
  }
  .cart-title{
    font-size: 1.2em;
  }
  .cart-price{
    font-size: 1.2em;
  }
  .cart-item{
    padding: 1em;
    border-bottom: solid #59230F 1px;
  }
  .cart-topline{
    border-top: solid #59230F 1px;
  }
  .cart-checkout{
    padding-right: 1em;
    margin-top: 1em;
  }
  .cart-nothing{
    text-align: center;
    margin-top: 1em;
  }
  .cart-subtotal{
    font-size: 1.3em;
    text-decoration: underline;
  }
}
 .payment-status{
    text-align: center;
    border: solid #59230F 1px;
    margin: 1em;
    padding: 1em;
    color: white;
  }

.navbar {
  ::hover{
    background: none;
  }
  .navbar-item-active{
    text-decoration: underline;
    letter-spacing: 0.3rem;
    }
}
.product-grid {
  display: grid;
  gap: 50px 50px;
  margin-left: 2em;
  margin-right: 2em;
  @media (min-width: 600px) {
     grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 900px) {
     grid-template-columns: repeat(3, 1fr);
  }
}

.no-webgl{
  margin: 5vh 5vh 5vh 5vh;
  
  p{
    font-size: 25pt;
  }
  .cursor{
    a svg{
      display: inline-block;
      vertical-align:middle;
    }
  }
  .cursor::after {
    content: "";
    width: 5px;
    height: 25pt;
    background: $reddawn;
    display: inline-block;
    vertical-align: middle;
    animation: cursor-blink 1.5s steps(2) infinite;
    margin-left: 3px;

  }
}
@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}